@use "sass:map";
@import './bootstrap-variables.scss';
@import "~bootstrap/scss/bootstrap";

:root {
  @media(min-width: 978px){
    overflow: overlay;
  }
  --slasher-comments-bg-color: #{$slasher-comments-bg-color};
  --stroke-and-line-separator-color: #{$stroke-and-line-separator-color};
  --primary-light: #ffbdb6;
  --poster-border-color: #0e0e0e;
  --scroll-bar-width: #{$scroll-bar-width};

  --fs-1: #{$h1-font-size};
  --fs-2: #{$h2-font-size};
  --fs-3: #{$h3-font-size};
  --fs-4: #{$h4-font-size};
  --fs-5: #{$h5-font-size};
  --fs-6: #{$h6-font-size};
}

* {
  /* Foreground, Background */
  scrollbar-color: $stroke-and-line-separator-color #1F1F1F;
}

*::-webkit-scrollbar {
  width: $scroll-bar-width;
  /* Mostly for vertical scrollbars */
  height: 6px;
  /* Mostly for horizontal scrollbars */
}

*::-webkit-scrollbar-thumb {
  /* Foreground */
  background: $stroke-and-line-separator-color;
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  /* Background */
  background: #1F1F1F;
}

html {
  // Ideally, this is the only place where we want to set a pixel font size.
  // Everything else will be in rems (relative to this base size)
  // or ems (relative to parent element font size).
  font-size: 15px;

  @media(min-width: 980px) {
    scroll-padding-top: 95px; // NOTE: This needs to change if we change our desktop header height
  }
}


body {
  &::-webkit-scrollbar {
    /* Mostly for vertical scrollbars */
    width: 10px;
    /* Mostly for horizontal scrollbars */
    height: 10px;
  }
  --safe-area-inset-top: constant(safe-area-inset-top);
  --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  height: calc(100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom)));
}

abbr[title] {
  cursor: default;
}

.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.full {
    @media (min-width: 980px) {
      padding-top: 93.75px; // To account for fixed top navbar on desktop views
    }
  }
}

a:not(.btn) {
  text-decoration: underline;
}

.btn-link {
  text-decoration: none;
  border: none;

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

.cursor-zoom-in {
  cursor: zoom-in !important;
}

// This class is often used with flex box and truncated (ellipsis) text
.min-width-0 {
  min-width: 0;
}

// Many of our desktop screens have dark background that become transparent on mobile
.bg-mobile-transparent {
  @media(max-width: 979px) {
    background-color: transparent !important;
  }
}

.btn-primary {
  // See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
  @include button-variant(
    $background: $primary,
    $border: #FF1700,
    $color: #000000,
    $hover-color: #000000,
    $active-color: #000000
  );
}

.btn-secondary {
  // See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
  @include button-variant(
    $background: $secondary,
    $border: #333333,
    $color: #ffffff,
    $hover-color: #ffffff,
    $active-color: #ffffff
  );
}

.btn-dark {
  // See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
  @include button-variant(
    $background: $dark,
    $border: $dark,
    $color: $light,
    $hover-background: #333,
    $hover-color: #ffffff,
    $active-background: #333,
    $active-color: #ffffff
  );
}

.btn-form {
  // See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
  @include button-variant(
    $background: #1f1f1f,
    $border: $stroke-and-line-separator-color,
    $color: #ffffff,
    $hover-background: #1f1f1f,
    $hover-color: #ffffff,
    $active-background: $primary,
    $active-color: #ffffff
  );
}

.btn-black {
  // See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
  @include button-variant(
    $background: #000000,
    $border: $stroke-and-line-separator-color,
    $color: #ffffff,
    $hover-color: #ffffff,
    $hover-border: lighten($stroke-and-line-separator-color, 20%),
    $active-color: #ffffff
  );
}

.btn-filter {
  // See: https://getbootstrap.com/docs/5.2/components/buttons/#sass-mixins
  @include button-variant(
    $background: #383838,
    $border: $stroke-and-line-separator-color
  )
}

html {
  height: 100%;
  scroll-behavior: auto !important;
}

body {
  background-color: #000000;

  // NOTE: Keeping this here in case we decide to go back to a gradient later
  // background-color: #0B0B0B;
  // background-repeat: no-repeat;
  // background-image: -webkit-gradient(linear, top, bottom, color-stop(0%, #161618), color-stop(1200px, #0B0B0B), color-stop(100%, transparent));
  // /* Chrome, Safari4+ */
  // background-image: -webkit-linear-gradient(top, #161618 0%, #0B0B0B 1200px, transparent 700px);
  // /* Chrome10+, Safari5.1+ */
  // background-image: -moz-linear-gradient(top, #161618 0%, #0B0B0B 1200px, transparent 700px);
  // /* FF3.6+ */
  // background-image: linear-gradient(to bottom, #161618 0%, #0B0B0B 1200px, transparent 700px);
  // background-attachment: fixed;
}

.rc-mentions {
  display: inline-block;
  position: relative;
  white-space: pre-wrap;
  border: none !important;
  border-radius: 13px !important;
  width: 100%;
}

.rc-mentions>textarea,
.rc-mentions-measure {
  font-size: inherit;
  font-size-adjust: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
  line-height: inherit;
  vertical-align: top;
  overflow: inherit;
  word-break: inherit;
  word-wrap: break-word;
  overflow-x: initial;
  overflow-y: auto;
  letter-spacing: inherit;
  white-space: inherit;
  tab-size: inherit;
  direction: inherit;
}

.rc-mentions>textarea {
  border: none;
  width: 100%;
  background: var(--bs-secondary);
  color: white;
  padding: 10px;
  font-size: 14px;
}

.rc-mentions-measure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  color: transparent;
  z-index: -1;
  padding: 1rem;
}

.rc-mentions-dropdown {
  position: absolute;
  padding: 1rem 0rem;
}

.rc-mentions-dropdown-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  max-height: 150px;
}

.rc-mentions-dropdown-menu-item {
  cursor: pointer;
}

.rc-mentions {
  font-size: 14px;
  border: 1px solid #999;
  border-radius: 3px;
  overflow: hidden;
}

.rc-mentions-dropdown {
  border: 1px solid gray;
  border-radius: 10px;
  background: rgb(15, 15, 15);
  overflow: hidden;
  z-index: 9999;
}

.rc-mentions-dropdown-menu-item {
  padding: 4px 8px;
}

.rc-mentions-dropdown-menu-item-active {
  background: var(--bs-primary);
  color: var(--bs-secondary);
}

.soft-half {
  padding: 7px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

textarea {
  resize: none;
}


/*****************
 * Accessibility *
 *****************/

#skip-to-main-content-link {
  z-index: 9999;
  position: absolute;
  background-color: #000;
  top: 0;
  text-align: center;
  border: 1px solid #aaa;
  padding: 1em;
  margin: 1em 2em;
}

.form-control:focus-visible {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba($stroke-and-line-separator-color, 1);
}

.btn-close {
  // Use white X instead of black X
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3E%3Cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.btn-close:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba($stroke-and-line-separator-color, 1);
}

.btn:focus-visible {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba($stroke-and-line-separator-color, 1);
}

:focus-visible {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba($stroke-and-line-separator-color, 1);
}

@font-face {
  font-family: "SearsTower";
  src: url("../fonts/Sears_Tower.ttf") format(truetype);
}

.form-select:focus {
  box-shadow: 0 0 0 1px var(--stroke-and-line-separator-color);
}

body.modal-open {
  overflow: visible !important;
  padding-right: 0 !important;
}

body.modal-open .navbar{
  padding-right: 0 !important;
}

@import "./_public_header_and_footer";

.swiper-wrapper {
  // SD-1470 Fix the irregular width of `right-sidebar` for `posts` tab when compared to
  // other profile pages like About, Friends, Posts, Photos tabs.
  width: 0px !important;
}