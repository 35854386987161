$prefix: 'bs-'; // default bootstrap value

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 980px,
  xl: 1200px,
  xxl: 1440px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1428px
) !default;

$primary: #FF1800;
$secondary: #000000;
$info: #007c82;
$warning: #e64d03;
$danger: #FF1800; // NOTE: this is the same as primary and that's intentional
$success: #00FF0A;
$light: #cccccc;
$dark: #141414;

$slasher-comments-bg-color: #171717;
$stroke-and-line-separator-color: #3A3B46;
$scroll-bar-width: 8px;

$border-radius-lg: .625rem;

$font-family-base: 'Roboto, sans-serif';
$font-size-base: 1rem !default;
$h1-font-size:   $font-size-base * 1.86666667 !default;
$h2-font-size:   $font-size-base * 1.13333333 !default;
$h3-font-size:   $font-size-base * 1.06666667 !default;
$h4-font-size:   $font-size-base * 1 !default;
$h5-font-size:   $font-size-base * 0.93333333 !default;
$h6-font-size:   $font-size-base * 0.8 !default;

$body-color: #ffffff;
$link-color: $body-color;

$offcanvas-bg-color: $dark;

$btn-font-weight: 'bold';
$input-btn-padding-y: 0.4723rem;
$btn-focus-width: 2px;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 1rem;

$modal-header-border-color: $stroke-and-line-separator-color;

$input-bg: $dark;
$input-border-color: $stroke-and-line-separator-color;
$input-focus-border-color: brighten($input-border-color, 75%);
$input-box-shadow: none;

$form-check-input-border: 1px solid $stroke-and-line-separator-color;

// $input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;
$input-btn-focus-width: 1px;
$input-btn-focus-color: $stroke-and-line-separator-color;

$modal-content-border-color: $stroke-and-line-separator-color;
